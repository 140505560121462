<template>
  <static-page title="BujinTV Terms Of Use">
    <p>Last updated: 18 October 2020</p>

    <h2>1. Acceptance of Terms and Conditions</h2>

    <ul>
      <li>
        1.1. These Terms and Conditions incorporate our Privacy Notice (which
        can be accessed at
        <a href="../privacy">www.bujin.tv/privacy</a>).
      </li>
      <li>
        1.2. These terms of use (“Terms of Use”) constitute an agreement between
        you and BujinTV (“we” “us” and/or “BujinTV”) regarding goods or services
        offered to you by BujinTV through our websites and/or applications,
        including where we make BujinTV applications available on third party
        platforms or services.
      </li>
      <li>
        1.3. You agree to be legally bound by these Terms of Use when you
        register and use BujinTV.
      </li>
      <li>
        1.4. These Terms of Use contain important information about your rights
        and obligations and the restrictions that may apply when you use
        BujinTV. If you do not agree with any of these Terms of Use then you
        should not register for or use BujinTV.
      </li>
    </ul>

    <h2>2. The BujinTV Service</h2>

    <ul>
      <li>
        2.1. BujinTV offers you the opportunity to view videos, movies, trailers
        and promotional material, graphics, logos, interfaces, layout and/or
        other audiovisual content (“BujinTV Content”, which includes any HTML
        tag or other code or metadata which is contained in, linked to, from and
        around such content). When we talk about the “BujinTV Service” we mean
        the service we provide to enable you to access BujinTV Content including
        our website and applications, all the features and functionality we make
        available to you, and any of our user interfaces or software associated
        with our service.
      </li>
      <li>
        2.2. To use the BujinTV Service you must register to become a
        subscriber. To become a subscriber and use the BujinTV Service you must
        be:
      </li>
      <li>2.2.1. at least 18 years of age</li>
      <li>
        2.2.2. a natural person (i.e. not a company or other business entity).
      </li>
      <li>
        2.3. If we ask you to, you agree to provide us with evidence of your
        identity.
      </li>
      <li>
        2.4. You cannot transfer your subscription to BujinTV to any other
        person.
      </li>
      <li>
        2.5. To become a subscriber of BujinTV, you must provide us with your
        first name, last name, email address and password, and a valid method of
        payment. From time to time, we may introduce the ability to provide
        payment through a third party and this will be considered a valid method
        of payment where that is the case.
      </li>
      <li>
        2.6. Your subscription of BujinTV may begin with a free trial. We will
        make clear to you whether this is the case during the registration
        process. At the end of your free trial, we will commence taking payments
        from your payment method on a monthly or annual basis (as selected by
        you at the time of registration). We may from time to time add other
        free trial periods or promotional offers and if we do so we will make it
        clear to you at the time of registration.
      </li>
      <li>2.7. Special offers and/or free trials cannot be combined.</li>
      <li>
        2.8. You will not be charged if you cancel your subscription during your
        free trial period. Please see the ‘Cancellation’ section below for full
        details.
      </li>
      <li>
        2.9. Free trials are only available to new subscribers and we reserve
        the right not to provide a free trial to any subscriber in our sole
        discretion to prevent abuse of the free trial period.
      </li>
    </ul>

    <h2>3. Billing</h2>

    <ul>
      <li>
        3.1. Unless you cancel, we will charge your payment method for your
        periodic subscription cost (which may vary depending on the plan you
        select). The first payment will be taken on the day you join, or if you
        have a free trial or other promotional offer, on the day after your free
        trial or promotional offer period expires. If that day does not exist in
        the relevant month, payment will be taken on the final day of the
        preceding month. For example, if you join BujinTV on 1 January and your
        free trial period is 7 days, your first payment would be taken on 8th
        January and (for monthly plans), thereafter on 8th February, 8th March,
        8th April and so on.
      </li>
      <li>
        3.2. If you purchase your BujinTV subscription as an in-app purchase via
        iOS your purchase is also subject to the applicable terms and conditions
        of Apple and payment will be charged to the payment method associated
        with your Apple account. You will be charged through your iTunes account
        at the end of the free trial period. Your subscription automatically
        renews unless auto-renew is turned off 24-hours before the end of the
        current period. You can manage your subscription and turn off
        auto-renewal from your iTunes account settings.
      </li>
      <li>
        3.3. Your monthly subscription cost is inclusive of any applicable VAT
        or other taxes.
      </li>
      <li>
        3.5. You can update your payment method at any time by going to the
        Account section of the BujinTV Service and updating your payment method.
        We may also update your payment method using information provided by our
        payment service providers, and following any update you authorise us to
        continue to charge the applicable payment method. If your payment method
        is through a third party, you may need to update your payment method
        through that third party. If our attempts to collect payment from any
        payment method fails, you authorise us to continue to attempt to collect
        payment from that payment method until the end of your monthly or annual
        billing cycle. If at the expiry of that cycle we are still unable to
        take payment, we may suspend or terminate your account until such time
        as you update your payment method to a valid method of payment. You are
        responsible for any fees charged by the issuer of your payment method
        (e.g. foreign transaction fees). Please check with the provider of your
        payment method for details.
      </li>
      <li>
        3.6. We may update the price of any of our subscription plans from time
        to time by giving notice to you of the changes to your registered email
        address. We will give you at least 30 days notice of any changes and you
        should cancel your subscription before the changes take effect if you do
        not agree to them. Please note it is your responsibility to provide us
        with a valid email address you can access when registering and we will
        assume that you are contactable at the email address you provide. You
        can update your registered email address at any time in the Account
        section of the BujinTV website.
      </li>
    </ul>

    <h2>4. Cancellation</h2>

    <ul>
      <li>
        4.1. You can cancel your BujinTV subscription at any time. To cancel, go
        to the Account page and follow the instructions. If you cancel, your
        subscription will continue until the end of your billing cycle. Please
        note that unless required to do so by applicable law, we do not provide
        refunds for partial billing cycles. If you registered for BujinTV
        through a third party or you use a third party payment method, you may
        need to cancel through that third party. If you registered for BujinTV
        and purchased your subscription as an in-app purchase via iOS you can
        cancel your subscription through the Settings menu on your device.
      </li>
      <li>
        4.2. If you cancel your BujinTV subscription before the expiry of your
        free trial, you will not be charged. If you sign up via iOS, please note
        that you will need to turn auto-renew off in your iTunes setting 24
        hours before the expiry of your free trial to avoid being charged.
      </li>
      <li>
        4.3. When you register to become a subscriber of BujinTV, you
        acknowledge that you will lose the statutory right to cancel provided
        under the Consumer Rights Act 2015 because we commence providing the
        services to you immediately upon the commencement of your subscription.
        However, you can still cancel at any time during your free trial (if
        applicable) without being charged.
      </li>
    </ul>

    <h2>5. Using the BujinTV Service</h2>

    <ul>
      <li>
        5.1. To be able to view or download BujinTV Content using the BujinTV
        Service you must have a compatible device and an active broadband
        internet connection or mobile network connection. Please see our FAQs
        for further information about using the BujinTV Service and the minimum
        technical requirements It is your responsibility to ensure that your
        equipment, systems and connectivity are suitable to enable you to access
        the BujinTV Service before you decide to become a subscriber. We are not
        responsible in any way for your ability to view BujinTV Content, even if
        your equipment and systems meet the minimum technical requirements. You
        are responsible for any costs and other charges or expenses charged by
        your Internet Service Provider or network operator in relation to your
        internet service, your mobile service and any use of the BujinTV
        Service.
      </li>
      <li>5.2. We reserve the right to:</li>
      <li>
        5.2.1. remove and/or alter the BujinTV Content available via the BujinTV
        Service and/or the functionality of the BujinTV Service at any time and
        for any reason whatsoever; and/or
      </li>
      <li>
        5.2.2. limit the number of devices you can use to access the BujinTV
        Service, whether concurrently or otherwise, if we have reasonable
        grounds for believing that your usage breaches these Terms of Use.
      </li>
      <li>
        5.3. All BujinTV Content is subject to change. In particular, we do not
        guarantee that any particular piece of BujinTV Content will be available
        on the BujinTV Service.
      </li>
      <li>
        5.4. We will make reasonable efforts to keep the BujinTV Service
        operational at all times, however we cannot guarantee that your use of
        the BujinTV Service will be uninterrupted or error-free. From time to
        time we may temporarily suspend or remove the BujinTV Service for
        technical, maintenance or operational reasons.
      </li>
      <li>
        5.5. Your password is confidential to you and you are responsible for
        keeping it secure. If you know or suspect your password has been
        compromised you must change your password as soon as possible using the
        functionality provided within the BujinTV Service. We will never ask you
        for your password in an email or when calling you by telephone. We will
        never invite you to log in to your account through a link in any email
        communication you receive from us. If we believe there has been, or
        there may be, a breach of security we may suspend accounts and/or
        require that passwords be changed.
      </li>
      <li>
        5.6. We may make some BujinTV Content available to download so you can
        view offline where your device supports it. Once downloaded, content
        will be available for a limited number of days and once you commence
        viewing the downloaded content it will be available for a limited period
        of time. We do not guarantee that all titles will be available for
        download.
      </li>
    </ul>

    <h2>6. Parental controls</h2>

    <ul>
      <li>
        6.1. Some of the BujinTV Content available via the BujinTV Service will
        have a symbol with Guidance information. We provide this information to
        allow you to make an informed choice about whether you wish to view the
        BujinTV Content or allow your children to view the BujinTV Content.
      </li>
    </ul>

    <h2>7. Security Technology</h2>

    <ul>
      <li>
        7.1. We use security and digital rights management technology for the
        BujinTV Service to protect the material and content made available on
        the BujinTV Service. You must not penetrate, remove or otherwise alter
        or interfere with any security measures used in connection with the
        BujinTV Service, or attempt to do any of those things or assist any
        other person in doing or attempting to do any of those things. We
        reserve the right to terminate or suspend your BujinTV subscription if
        we suspect or discover that you have done so.
      </li>
    </ul>

    <h2>8. Geoblocking</h2>

    <ul>
      <li>
        8.1. The BujinTV Service may use “geo-blocking” measures to prevent you
        from accessing the services from outside the Territory. This is
        necessary for us to comply with the terms of our content licences. You
        agree not to attempt to access, and not to attempt to circumvent the
        geo-blocking measures to access, the BujinTV Service from outside the
        Territory.
      </li>
    </ul>

    <h2>9. General restrictions</h2>

    <ul>
      <li>
        9.1. You agree that you will comply with our reasonable instructions
        regarding your use of the BujinTV Service. You agree that you will not
        (and you will not allow any other person to) use the BujinTV Service for
        any purpose which is commercial, unlawful or otherwise improper. In
        particular, except as expressly permitted by these Terms of Use or
        otherwise by us, you will not by any means as a result of or in
        connection with your use of the BujinTV Service:
      </li>
      <li>9.1.1. share your login with anyone else;</li>
      <li>
        9.1.2. stream BujinTV Content concurrently on more than four (4) devices
        per account; 9.1.3. cremove, alter, bypass, avoid, interfere with or
        circumvent the digital rights management software or geo-blocking
        software;
      </li>
      <li>
        9.1.4. copy, download (unless we have made such download functionality
        available on the BujinTV Service), lend, hire, broadcast, stream,
        capture, reproduce, archive, share, distribute, modify, translate,
        display,transmit or make the BujinTV Content available to the public;
      </li>
      <li>
        9.1.5. remove or alter any copyright or other proprietary notice from
        the BujinTV Service or from any BujinTV Content;
      </li>
      <li>
        9.1.6. extract any data or metadata from the BujinTV Service or any
        BujinTV Content nor create any index or database incorporating any
        BujinTV Content or any part of it;
      </li>
      <li>
        9.1.7. block, disable, obscure or impair any promotional material,
        sponsorship or advertising on the BujinTV Service or associated with any
        BujinTV Content;
      </li>
      <li>
        9.1.8. capture or store any personal information relating to other users
        of the BujinTV Service;
      </li>
      <li>
        9.1.9. incorporate the BujinTV Service into another service or website
        or make it available via frames;
      </li>
      <li>
        9.1.10.reverse engineer, de-compile, disassemble, alter, duplicate,
        modify, rent, lease, loan, sub-license, make copies, create derivative
        works from, distribute or provide others with the BujinTV Service or any
        BujinTV Content;
      </li>
      <li>
        9.1.11. create any works or materials derived from or based on any
        BujinTV Content or the BujinTV Service;
      </li>
      <li>
        9.1.12. use the BujinTV Service in any way that may lead to the
        encouragement, procurement or carrying out of any criminal or unlawful
        activity, or cause distress, harm or inconvenience to any other person;
        or
      </li>
      <li>
        9.1.13. do anything that may cause damage to the BujinTV Service or our
        servers, systems or equipment or those of third parties, nor access or
        attempt to access any user’s data or to penetrate or attempt to
        penetrate any applicable security measures.
      </li>
      <li>
        9.2. You agree to compensate us in respect of any damages suffered by us
        or any of our losses resulting from any claim made by a third party in
        each case in respect of any matter arising from your use of the BujinTV
        Service in breach of these Terms of Use or from your violation of any
        applicable law or regulation.
      </li>
      <li>
        9.3. You agree not to give any indication that you have any commercial
        relationship with us, or that you are our agent or that we have endorsed
        any submission you may make.
      </li>
      <li>
        9.4. We reserve the right to suspend, restrict or terminate your access
        to the BujinTV Service at any time at our sole discretion. We may also
        suspend or terminate your subscription if you do not comply with these
        Terms of Use.
      </li>
    </ul>

    <h2>10. Trademarks and copyright</h2>

    <ul>
      <li>
        10.1. All intellectual property rights in the BujinTV Service and all
        BujinTV Content and any other content made available via the BujinTV
        Service shall remain at all times the property of BujinTV or BujinTV’s
        licensors. The names, images and logos identifying us, our partners or
        third parties and our/their products and services contained within the
        BujinTV Service are proprietary marks and may not be reproduced or
        otherwise used without our express permission (and where relevant the
        express permission of the relevant third party). Nothing in these Terms
        of Use shall be construed as conferring by implication any licence or
        right to use any trade mark, patent, design right or copyright of
        BujinTV or of any third party. You do not acquire any ownership right in
        any BujinTV Content by accessing the BujinTV Content on the BujinTV
        Service.
      </li>
    </ul>

    <h2>11. Third party sites</h2>

    <ul>
      <li>
        11.1. The BujinTV Service may contain links (whether by way of an
        advertisement or otherwise) to other third party sites and services.
        Such links are provided for your ease of reference and convenience only.
        We have no control over those third party sites or services and are not
        responsible for their contents. We do not endorse the material contained
        in any third party’s sites or services and have no association with
        their operators. You agree that we will not be a party to any
        transaction or contract with a third party that you may enter into and
        we will not be liable to you for any loss or damage which you may suffer
        by using those third party sites and services. You agree that you will
        not involve us in any dispute you may have with such third party sites
        and services.
      </li>
    </ul>

    <h2>12. Limitation of Liability</h2>

    <ul>
      <li>
        12.1. All services are provided on an ‘as is’ and ‘as available’ basis.
        We cannot promise that the BujinTV Service will be free from errors or
        omissions nor that it will be available uninterrupted and in a fully
        operating condition, or free from viruses or other harmful components.
        Not all of the features and functionality of the BujinTV Service will
        work on all devices. We make no guarantee that all or any features of
        the BujinTV Service will work on any particular device. Whilst we do
        take reasonable precautions to prevent the occurrence of computer
        viruses and/or other malicious programmes, we do not guarantee that they
        will not be present.
      </li>
      <li>
        12.2. We will not be responsible for any damage or loss caused to you
        where such damage or loss is not reasonably foreseeable to you and us
        when you use the BujinTV Service, including where the damage or loss
        results from our breach of these Terms of Use.
      </li>
      <li>
        12.3. The BujinTV Service may be suspended temporarily and without
        notice in the case of system failure, maintenance or repair or for
        reasons beyond our reasonable control. Except as set out elsewhere in
        these Terms of Use we will not be liable to you or to any other person
        in the event that all or part of the BujinTV Service is unavailable,
        discontinued, modified or changed in any way.
      </li>
      <li>12.4. We do not accept any liability for:</li>
      <li>
        12.4.1. any damage or loss caused to you where you are not entering into
        these Terms of Use as a consumer;
      </li>
      <li>
        12.4.2. any failure, any suspension and/or any termination of access to
        the BujinTV Service or any BujinTV Content in connection with or arising
        out of a force majeure event. In these Terms of Use, a “force majeure”
        event shall include, without limitation, strikes, lock-outs or other
        industrial disputes, nuclear accident or acts of God, war or terrorist
        activity, pandemics, riot, civil commotion, malicious damage, compliance
        with any law or government order, rule regulation or direction, accident
        or breakdown of plant or machinery, fire, flood, storm or default of
        suppliers, and where they are beyond our reasonable control, any other
        acts, events, omissions or accidents; and/or
      </li>
      <li>
        12.4.3. any claims brought against you by a third party except as stated
        in these Terms of Use.
      </li>
      <li>12.5. Nothing in these Terms and Conditions will:</li>
      <li>
        12.5.1. restrict your statutory rights as a consumer (including your
        rights to receive a reasonable standard of service, more details about
        which can be obtained from your local Trading Standards Office or
        Citizen's Advice Bureau); or
      </li>
      <li>
        12.5.2. limit our liability for death or personal injury resulting from
        our negligence or for fraud.
      </li>
    </ul>

    <h2>13. Third party platform operators</h2>

    <ul>
      <li>
        13.1. These Terms of Use do not govern your relationship with Apple. You
        should check Apple’s websites for details of the terms and conditions
        governing your relationship with Apple. Your use of the BujinTV Service
        may also be subject to Apple’s usage rules.
      </li>
    </ul>

    <h2>14. Other important terms</h2>

    <ul>
      <li>
        14.1. BujinTV may from time to time issue voucher codes which can be
        redeemed during your registration in return for special offers. BujinTV
        voucher codes can only be redeemed on a one per person basis via
        <a href="../">www.bujin.tv</a>. Each voucher code will have its own
        terms and conditions attached, which may include restricting use to a
        particular group of people, and those terms and conditions will apply
        along with these Terms of Use. When you use a voucher code to access the
        BujinTV Service you will also be required to create a BujinTV account
        and provide a valid payment method. We may use your personal data to
        validate your entitlement to any voucher code you use, and we may
        withdraw the benefit of any voucher code if you do not meet the
        applicable terms and conditions.
      </li>
      <li>
        14.2. Voucher codes cannot be combined, re-sold, transferred for value,
        redeemed for cash, used to purchase other voucher codes or used in a
        manner otherwise prohibited by our Terms of Use. We are not responsible
        if any voucher code is lost, stolen or used without your permission. We
        do not guarantee that voucher codes will always be effective and we may
        withdraw any voucher code at any time without liability to you, subject
        always to your statutory rights as a consumer. We reserve the right to
        change these terms and conditions from time to time at our discretion.
      </li>
      <li>
        14.3. Please note that we may update and amend these Terms of Use from
        time to time. By proceeding to use the BujinTV Service you agree to be
        bound by any changes to these Terms of Use. However, it is your
        responsibility to ensure you review these Terms of Use regularly at
        <a href="../terms">www.bujin.tv/terms</a> to familiarise yourself with
        any changes.
      </li>
    </ul>

    <h2>15. Other important terms</h2>

    <ul>
      <li>
        15.1. If any provision of these Terms of Use shall be found by any court
        or administrative body of competent jurisdiction to be invalid or
        unenforceable, such invalidity or unenforceability shall not affect the
        other provisions of these Terms of Use which shall remain in full force
        and effect.
      </li>
      <li>
        15.2. These Terms of Use are available in English only and shall be
        governed by and construed in accordance with the laws of England (or the
        laws of Scotland or Northern Ireland if you are domiciled there).
        Disputes arising in connection with these Terms and Conditions shall be
        subject to the exclusive jurisdiction of the courts of England and Wales
        unless you choose the jurisdiction of your domicile in Scotland or
        Northern Ireland. Apple and its subsidiaries may also enforce any of
        these Terms and Conditions against you.
      </li>
      <li>
        15.3. Please note that we may update and amend these Terms of Use from
        time to time. By proceeding to use the BujinTV Service you agree to be
        bound by any changes to these Terms of Use. However, it is your
        responsibility to ensure you review these Terms of Use regularly at
        <a href="../terms">www.bujin.tv/terms</a> to familiarise yourself with
        any changes.
      </li>
      <li>
        15.4. The BujinTV word and figurative marks are trademarks of Bujin
        Media LLP and are used under licence.
      </li>
    </ul>

    <h2>16. Contact Us</h2>

    <ul>
      <li>
        16.1. If you have any questions, queries or complaints or need help
        using the BujinTV Service then you can email us at help@bujinmedia.com.
        Bujin Media LLP is a company incorporated in England and Wales
        (registered number OC433499). Our registered office is 61 Bridge Street,
        Kington, Herefordshire, HR5 3DJ U.K.
      </li>
    </ul>

    <copyright-disclaimer></copyright-disclaimer>
  </static-page>
</template>

<script>
import StaticPage from "@/components/StaticPage";
import CopyrightDisclaimer from "@/components/CopyrightDisclaimer";

export default {
  name: "terms",

  components: { CopyrightDisclaimer, StaticPage },
};
</script>
